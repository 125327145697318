module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[],"headers":{"/fonts/*":["Cache-Control: public, max-age=31536000, immutable"],"**/fonts/**/*.otf":["Cache-Control: public, max-age=31536000, immutable"],"**/*.otf":["Cache-Control: public, max-age=31536000, immutable"],"**/*.woff":["Cache-Control: public, max-age=31536000, immutable"],"**/*.woff2":["Cache-Control: public, max-age=31536000, immutable"],"/public/**/*.html":["Cache-Control: public, max-age=0, must-revalidate"],"/sw.js":["Cache-Control: public, max-age=0, must-revalidate"],"/public/page-data/*":["Cache-Control: public, max-age=0, must-revalidate"],"/public/*.js":["Cache-Control: public, max-age=0, must-revalidate"],"/public/**/*.js":["Cache-Control: public, max-age=0, must-revalidate"],"**/*.js":["Cache-Control: public, max-age=31536000, immutable"],"**/*/*.js":["Cache-Control: public, max-age=31536000, immutable"],"/*.js":["Cache-Control: public, max-age=31536000, immutable"],"/public/static/*.otf":["Cache-Control: public, max-age=31536000, immutable"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
